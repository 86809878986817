import { Loader } from "@googlemaps/js-api-loader";
import { getInfowindowContent } from "./getInfoWindowContent";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  defaultCenter,
  defaultZoom,
  mapOptions,
  icons,
  overviewMapOffset,
  mapWithInfoMapOffset,
  mapArbitraryMapOffset,
} from "./mapOptions";
import {
  offsetCenter,
  fitBoundsToMarkers,
  addMarkerHoverState,
  getInfoWindow,
  addInfoWindowListeners,
  clusterRenderer,
  setMarker,
} from "./mapHelpers";

let overviewMap = {};
let mapWithInfoMap = {};
let mapMapArbitraryMap = {};
let neighborhoodsMap = {};
let markerCluster = null;
let mapMarkers = [];
let infoWindows = [];
let isMapLoaded = false;

// Load Google Maps
// ToDo: Put API key into environment variable
const mapsLoader = new Loader({
  apiKey: "AIzaSyBSzie3DaOoARtMlb9RgHbiN6yfm6vLZxE",
  version: "weekly",
  libraries: ["places"],
});

export const loadNeighborhoodsMap = () => {
  return mapsLoader
    .load()
    .then((google) => {
      neighborhoodsMap = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      markerCluster = new MarkerClusterer({
        map: neighborhoodsMap,
        renderer: clusterRenderer,
      });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const updateNeighborhoodsMap = (mapInfo, offsetx, offsety) => {
  return mapsLoader
    .load()
    .then((google) => {
      if (neighborhoodsMap instanceof google.maps.Map) {
        neighborhoodsMap?.setZoom(11);
        neighborhoodsMap?.setCenter(defaultCenter);
      }
      mapMarkers.forEach((marker) => marker.setMap(null));
      mapMarkers = [];
      infoWindows.forEach((infoWindow) => infoWindow.close());
      infoWindows = [];
      if (markerCluster instanceof MarkerClusterer) {
        markerCluster.clearMarkers();
      }

      const infowindowContent = getInfowindowContent(mapInfo) ?? false;

      mapInfo.value.nodes.forEach((node, index) => {
        if (!node.hideCommunity) { 
          const marker = setMarker(node, icons, neighborhoodsMap, markerCluster);
          addMarkerHoverState(marker, icons);
          const infoWindow = getInfoWindow(infowindowContent, index);
          infoWindows.push(infoWindow);
          mapMarkers.push(marker);
          fitBoundsToMarkers(neighborhoodsMap, mapMarkers);
          addInfoWindowListeners(
            infoWindow,
            infoWindows,
            marker,
            mapMarkers,
            neighborhoodsMap,
            node.listItemId,
            false
          );
          if (typeof offsetx !== "undefined") {
            offsetCenter(
              neighborhoodsMap,
              neighborhoodsMap?.getCenter(),
              offsetx,
              offsety
            );
          }
        }
      });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const loadMapWithInfoMap = () => {
  return mapsLoader
    .load()
    .then((google) => {
      mapWithInfoMap = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      mapWithInfoMap.setOptions({
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const loadMapArbitraryMap = () => {
  return mapsLoader
    .load()
    .then((google) => {
      mapMapArbitraryMap = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      mapMapArbitraryMap.setOptions({
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const updateMapWithInfoMap = (latitude, longitude, title) => {
  return mapsLoader
    .load()
    .then((google) => {
      const marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        title: title,
        icon: icons.purple.icon,
        map: mapWithInfoMap,
      });
      mapMarkers.push(marker);
      if (
        mapWithInfoMap instanceof google.maps.Map &&
        process.client &&
        window.innerWidth >= mapWithInfoMapOffset.breakpoint
      ) {
        mapWithInfoMap?.setCenter({ lat: latitude, lng: longitude });
        mapWithInfoMap?.setZoom(15);
        offsetCenter(
          mapWithInfoMap,
          mapWithInfoMap?.getCenter(),
          mapWithInfoMapOffset.x,
          mapWithInfoMapOffset.y
        );
      } else {
        loadMapWithInfoMap();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const updateMapArbitraryMap = (mapInfo) => {
  return mapsLoader
    .load()
    .then((google) => {
      const marker = new google.maps.Marker({
        position: { lat: mapInfo.latitude, lng: mapInfo.longitude },
        title: mapInfo.title,
        icon: icons.purple.icon,
        map: mapMapArbitraryMap,
      });

      marker.addListener("mouseover", (event) => {
        event.domEvent.target.src = icons.green.icon;
      });
      marker.addListener("mouseout", (event) => {
        event.domEvent.target.src = icons.purple.icon;
      });

      const infoWindow = new google.maps.InfoWindow({
        content: mapInfo.infoWindowContent,
      });

      marker.addListener("click", () => {
        if (process.client) {
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
          // offsetCenter(
          //   map,
          //   marker.getPosition(),
          //   mapArbitraryMapOffset.x,
          //   mapArbitraryMapOffset.y
          // );
        }
      });

      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });

      infoWindow.addListener("closeclick", () => {
        if (process.client) {
          // offsetCenter(
          //   map,
          //   map.getCenter(),
          //   overviewMapOffset.x,
          //   overviewMapOffset.y
          // );
        }
      });
      mapMarkers.push(marker);
      if (
        mapMapArbitraryMap instanceof google.maps.Map &&
        process.client &&
        window.innerWidth >= mapArbitraryMapOffset.breakpoint
      ) {
        mapMapArbitraryMap?.setCenter({ lat: mapInfo.latitude, lng: mapInfo.longitude });
        mapMapArbitraryMap?.setZoom(15);
        offsetCenter(
          mapMapArbitraryMap,
          mapMapArbitraryMap?.getCenter(),
          mapArbitraryMapOffset.x,
          mapArbitraryMapOffset.y
        );
      } else {
        loadMapArbitraryMap();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const loadOverviewMap = () => {
  return mapsLoader
    .load()
    .then((google) => {
      overviewMap = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      overviewMap.setOptions({
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
      });
      markerCluster = new MarkerClusterer({
        map: overviewMap,
        renderer: clusterRenderer,
      });
      isMapLoaded = true;
    })
    .catch((e) => {
      console.error(e);
    });
};

export const updateOverviewMap = (mapInfo, callback = false) => {
  if (!isMapLoaded) {
    return;
  }

  return mapsLoader
    .load()
    .then((google) => {
      if (overviewMap instanceof google.maps.Map) {
        overviewMap?.setZoom(defaultZoom);
        overviewMap?.setCenter(defaultCenter);
      }
      mapMarkers.forEach((marker) => marker.setMap(null));
      mapMarkers = [];
      infoWindows.forEach((infoWindow) => infoWindow.close());
      infoWindows = [];
      if (markerCluster instanceof MarkerClusterer) {
        markerCluster.clearMarkers();
      }

      //console.log(mapInfo);

      const infowindowContent = getInfowindowContent(mapInfo) ?? false;

      switch (mapInfo.value.mapView) {
        case "model-homes":
          mapInfo.value.nodes.forEach((node, index) => {
            const marker = setMarker(node, icons, overviewMap, markerCluster);
            //console.log('Mmarker:', marker);
            addMarkerHoverState(marker, icons);
            const infoWindow = getInfoWindow(infowindowContent, index);
            //console.log('MinfoWindow:', infoWindow);
            infoWindows.push(infoWindow);
            mapMarkers.push(marker);
            fitBoundsToMarkers(overviewMap, mapMarkers);
            addInfoWindowListeners(
              infoWindow,
              infoWindows,
              marker,
              mapMarkers,
              overviewMap,
              node.listItemId
            );
          });
          break;

        case "communities":
          mapInfo.value.nodes.forEach((node, index) => {
            if (!node.hideCommunity) { 
              const marker = setMarker(node, icons, overviewMap, markerCluster);
              addMarkerHoverState(marker, icons);
              const infoWindow = getInfoWindow(infowindowContent, index);
              infoWindows.push(infoWindow);
              mapMarkers.push(marker);
              fitBoundsToMarkers(overviewMap, mapMarkers);
              addInfoWindowListeners(
                infoWindow,
                infoWindows,
                marker,
                mapMarkers,
                overviewMap,
                node.listItemId
              );
            }
          });
          break;

        case "quick-move-in":
          mapInfo.value.nodes.forEach((node, index) => {
            if (!node.hideOnMap) {
              const marker = setMarker(node, icons, overviewMap, markerCluster);
              //console.log('Qmarker:', marker);
              addMarkerHoverState(marker, icons);
              const infoWindow = getInfoWindow(infowindowContent, index);
              //console.log('QinfoWindow:', infoWindow);
              infoWindows.push(infoWindow);
              mapMarkers.push(marker);
              fitBoundsToMarkers(overviewMap, mapMarkers);
              addInfoWindowListeners(
                infoWindow,
                infoWindows,
                marker,
                mapMarkers,
                overviewMap,
                node.listItemId
              );
            }
          });
          break;
      }

      if (
        overviewMap instanceof google.maps.Map &&
        process.client &&
        window.innerWidth >= overviewMapOffset.breakpoint
      ) {
        offsetCenter(
          overviewMap,
          overviewMap?.getCenter(),
          overviewMapOffset.x,
          overviewMapOffset.y
        );
      }

      if (callback) {
        callback();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};
